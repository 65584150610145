import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

const Header = styled.header`
  background: black;
  width: 100%;
  padding: 1.5em 0;
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: flex;
    justify-content: space-between;
  }

  li {
    display: inline-block;
    margin-left: 1em;
    &:first-child {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: 600;
    transition: all 0.2s;
    border-bottom: 2px solid ${props => props.theme.colors.base};
    &:hover {
      color: white;
    }
  }
`

const activeLinkStyle = {
    color: 'white',
}

const Menu = () => {
    return (
        <Header>
            <Nav>
                <ul>
                    <li>
                        <Link to="/" exact activeStyle={activeLinkStyle}>
                            Black Infinity Kennel
                        </Link>
                    </li>
                    <li>
                        <Link to="/gallery/" activeStyle={activeLinkStyle}>
                            Gallery
                        </Link>
                    </li>
                    <li>
                        <Link to="/awards/" activeStyle={activeLinkStyle}>
                            Awards
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact/" activeStyle={activeLinkStyle}>
                            Contact
                        </Link>
                    </li>
                </ul>
            </Nav>
        </Header>
    )
}

export default Menu
